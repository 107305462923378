import React, { useEffect, useState } from "react";
import {
  faEye,
  faEyeSlash,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import Input from "@app/components/Input/CMSInput";
import axiosSession from "@app/config/axiosSession";
import handleSkiplinkClick from "@app/helpers/handleSkiplinkClick";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";
import { Portal } from "@material-ui/core";
import { googleIcon } from "../../helpers/imgPaths";
import setH1TagFromTitleTag from "../../helpers/titleTag";
import properButtonsColor from "../../helpers/properButtonsColor";

const Login = ({ previous_url, message }) => {
  const [inputErrors, setInputErrors] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();
  const register_url = window.location.href.replace("login", "rejestracja");
  const { t } = useTranslation();
  const [isEmailInValidated, setIsEmailInValidated] = useState(false);

  const InputTypes = {
    text: "text",
    password: isPasswordVisible ? "text" : "password"
  };

  // specific breakpoint for auth views
  const authMobileBreakpoint = 1365;
  const isAuthMobile = useWindowWidth() < authMobileBreakpoint;

  setH1TagFromTitleTag();

  useEffect(() => {
    properButtonsColor(isAuthMobile);
  }, [isAuthMobile]);

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      notifySuccess(message.messages[0]);
    }
  }, [message, notifySuccess]);

  const { handleSubmit, register, errors, setError } = useForm();

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError(t("cms.plugins.login.errors.formError"))
      : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    let next = "/";
    if (window.location.search.includes("next=")) {
      next = window.location.search.split("next=")[1];
    }

    axiosSession
      .post("/api/rest-auth/login/", data)
      .then(function() {
        window.location.href = next;
      })
      .catch(function(error) {
        notifyError(error.response.data.non_field_errors[0]);
        setIsEmailInValidated([]);

        if (error.response.data.password) {
          setError("password", "", error.response.data.password);
        }

        if (error.response.data.non_field_errors) {
          setInputErrors(error.response.data.non_field_errors[0]);
        }

        if (error.response.data.email_validated) {
          setIsEmailInValidated(error.response.data.email_validated[0]);
        }
      });
  };

  return (
    <>
      <Portal container={document.querySelector(".skiplinks")}>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".header__nav-open")}
        >
          {t("cms.plugins.login.skiplinks.menu")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__container")}
        >
          {t("cms.plugins.login.skiplinks.form")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__title-row__register")}
        >
          {t("cms.plugins.login.skiplinks.register")}
        </button>
      </Portal>
      <section className="section wrapper">
        <nav className="breadcrumbs">
          <ul>
            <li>
              <a
                className="breadcrumbs__frontoffice-link"
                href="/"
                title={t("cms.plugins.login.mainPage")}
              >
                {t("cms.plugins.login.mainPage")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <span className="breadcrumbs__current">
                {t("cms.plugins.login.login")}
              </span>
            </li>
          </ul>
        </nav>
        <section className="section">
          <h1 id="mbc_header">{t("cms.plugins.login.login")}</h1>
          <div className="login__title-row">
            <h2 className="header--decorated">
              {t("cms.plugins.login.login")}
            </h2>
            <ArrowButton
              href={register_url}
              className="login__title-row__register"
            >
              {t("cms.plugins.login.register")}
            </ArrowButton>
          </div>
          <div className="login__container">
            <div className="form-group">
              {/* <a
                className="btn btn--facebook"
                href={"/accounts/facebook/login"}
              >
                <FontAwesomeIcon
                  className="icon-facebook"
                  icon={faFacebookSquare}
                  aria-hidden="true"
                />
                {t("cms.loginExternal.facebook")}
              </a> */}
              <a className="btn btn--google" href={"/accounts/google/login"}>
                <img
                  className="google-icon"
                  src={googleIcon}
                  aria-hidden="true"
                />
                {t("cms.loginExternal.google")}
              </a>
            </div>
            <p className="login__or-row">
              {t("cms.loginExternal.login-password")}
            </p>
            <form
              className="form-group"
              onSubmit={handleSubmit(onSubmit)}
              autocomplete="on"
            >
              <Input
                placeholder={t("cms.plugins.placeholders.username")}
                ariaLabel={`${t("cms.plugins.placeholders.username")} ${t(
                  "app.requiredField"
                )}`}
                name="username"
                label={t("cms.plugins.login.loginLabel")}
                required
                register={register({
                  required: t("cms.plugins.login.errors.required")
                })}
                errors={errors}
              />
              <div className="input-container">
                <Input
                  placeholder={t("cms.plugins.placeholders.password")}
                  ariaLabel={`${t("cms.plugins.placeholders.password")} ${t(
                    "app.requiredField"
                  )}`}
                  className="login__password-field"
                  name="password"
                  label={t("cms.plugins.login.passwordLabel")}
                  required
                  type={InputTypes.password}
                  register={register({
                    required: t("cms.plugins.login.errors.required")
                  })}
                  errors={errors}
                />
                <button
                  className="icon-end"
                  type="button"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  aria-label={t("cms.plugins.setPassword.passwordPreview")}
                >
                  <FontAwesomeIcon
                    className="show-password-btn__icon"
                    icon={isPasswordVisible ? faEyeSlash : faEye}
                  />
                </button>
              </div>

              <div className="login__links">
                {!isEmailInValidated.messages &&
                !isEmpty(isEmailInValidated) ? (
                  <a
                    role="button"
                    tabIndex="0"
                    href="/link-aktywacyjny/"
                    title={t("cms.plugins.activationLink.resend")}
                  >
                    {t("cms.plugins.activationLink.resend")}
                  </a>
                ) : (
                  ""
                )}
              </div>

              {inputErrors ? <p>{inputErrors}</p> : ""}

              <button className="btn" type="submit">
                {t("cms.plugins.login.login")}
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </form>
            <div className="login__links">
              <a
                role="button"
                tabIndex="0"
                href="/nie-pamietam-hasla/"
                title={t("cms.plugins.login.forgottenPassword")}
              >
                {t("cms.plugins.login.forgottenPassword")}
              </a>
              <a
                role="button"
                tabIndex="0"
                href="/nie-pamietam-nazwy-uzytkownika/"
                title={t("cms.plugins.login.forgottenLogin")}
              >
                {t("cms.plugins.login.forgottenLogin")}
              </a>
              <ArrowButton href={register_url} className="login__have-account">
                {t("cms.plugins.login.register")}
              </ArrowButton>
            </div>
            <div>
              <p>
                <span className="color-red">*</span> -{" "}
                {t("app.requiredDescription")}
              </p>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Login;
