import React, { useEffect, useRef, useState } from "react";
import {
  faEye,
  faEyeSlash,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import axiosSession from "../../config/axiosSession";
import handleSkiplinkClick from "@app/helpers/handleSkiplinkClick";
import isEmpty from "lodash/isEmpty";
import parse from "html-react-parser";
import { useForm } from "react-hook-form";
import useGenericToastify from "../../hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";
import { useTranslation } from "react-i18next";
import CMSCheckbox from "@components/Checkbox/CMSCheckbox";
import Input from "@components/Input/CMSInput";
import ArrowButton from "../../plugins/components/assets/Button/ArrowButton";
import { Portal } from "@material-ui/core";
import Modal from "../../plugins/components/assets/Modal/Modal";
import { googleIcon } from "../../helpers/imgPaths";
import setH1TagFromTitleTag from "../../helpers/titleTag";
import properButtonsColor from "../../helpers/properButtonsColor";

const types = {
  text: "text",
  textarea: "textarea",
  password: "password",
  checkbox: "checkbox"
};

const Register = props => {
  const reCaptchaSiteKey = props.captcha || "";
  const {
    handleSubmit,
    register,
    errors,
    setError,
    getValues,
    setValue
  } = useForm();
  const [inputErrorCaptcha, setInputErrorCaptcha] = useState(false);
  const [inputError, setInputError] = useState(false);
  const { notifyError } = useGenericToastify();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messages, setMessages] = useState({});
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isRepeatPasswordHidden, setIsRepeatPasswordHidden] = useState(true);
  const { t } = useTranslation();

  const login_url = window.location.href.replace("rejestracja", "login");

  // specific breakpoint for auth views
  const authMobileBreakpoint = 1365;
  const isAuthMobile = useWindowWidth() < authMobileBreakpoint;

  setH1TagFromTitleTag();

  useEffect(() => {
    properButtonsColor(isAuthMobile);
  }, [isAuthMobile]);

  //TODO: backend?
  const regulations = [
    {
      id: 1,
      name: "terms",
      label: t("cms.plugins.register.accepting"),
      link: t("cms.plugins.register.terms"),
      type: "terms",
      modalName: t("cms.plugins.register.term")
    },
    {
      id: 2,
      name: "agreements",
      label: t("cms.plugins.register.accepting"),
      link: t("cms.plugins.register.agreements"),
      type: "agreements",
      modalName: t("cms.plugins.register.agreement")
    }
  ];

  const fields = {
    first_name: t("cms.plugins.register.name"),
    last_name: t("cms.plugins.register.lastName"),
    email: "Email",
    username: t("cms.plugins.register.username"),
    password1: t("cms.plugins.register.password"),
    password2: t("cms.plugins.register.rePassword"),
    terms:
      t("cms.plugins.register.terms")
        .charAt(0)
        .toUpperCase() + t("cms.plugins.register.terms").slice(1),
    agreements:
      t("cms.plugins.register.agreements")
        .charAt(0)
        .toUpperCase() + t("cms.plugins.register.agreements").slice(1),
    recaptcha: "recaptcha"
  };

  const resetErrors = () => {
    setInputErrorCaptcha(false);
    setInputError(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      const writtenErrors = Object.keys(errors).map(key =>
        `${fields[key]} ${errors[key].message.toLowerCase()}.`.replace("*", "")
      );
      notifyError(
        <>
          <span>{t("cms.plugins.register.errors.formError")}</span>
          <span className="hidden-readable">{writtenErrors}</span>
        </>
      );
    }
  }, [errors, notifyError]);

  useEffect(() => {
    register({ name: "recaptcha" });
  });

  const onVerifyCaptcha = token => {
    setValue("recaptcha", token);
  };

  const reCaptchaRef = useRef(null);

  const onSubmit = data => {
    resetErrors();

    axiosSession
      .post("/rest-auth/registration/", data)
      .then(() => {
        window.location = "/register-email/";
      })
      .catch(error => {
        reCaptchaRef.current.reset();
        const currentErrors = error.response.data;

        let errorsToRead = Object.keys(currentErrors).map(key =>
          currentErrors[key].map(elem =>
            fields[key] === "recaptcha" ? `${fields[key]}: ${elem}` : elem
          )
        );
        errorsToRead = [].concat.apply([], errorsToRead);
        notifyError(
          <>
            <span>{t("cms.plugins.register.errors.formError")}</span>
            <span className="hidden-readable">{errorsToRead}</span>
          </>
        );

        if (error.response.data.email) {
          setError("email", "", error.response.data.email);
        }
        if (error.response.data.first_name) {
          setError("first_name", "", error.response.data.first_name);
        }
        if (error.response.data.last_name) {
          setError("last_name", "", error.response.data.last_name);
        }
        if (error.response.data.password1) {
          setError("password1", "", error.response.data.password1);
        }

        if (error.response.data.password2) {
          setError("password2", "", error.response.data.password2);
        }

        if (error.response.data.recaptcha) {
          setInputErrorCaptcha(error.response.data.recaptcha[0]);
        }

        if (error.response.data.username) {
          setError("username", "", error.response.data.username);
        }

        if (error.response.data.non_field_errors) {
          setError("username", "", error.response.data.non_field_errors[0]);
          setError("password1", "", error.response.data.non_field_errors[0]);
        }
      });
  };

  const displayDialog = regulation => {
    let content = "";
    if (regulation.type === "agreements") {
      axiosSession
        .get("/api/rules/agreements/all-active/")
        .then(response => {
          let items = response.data.agreements;
          content = items.map((f, index) =>
            parse(
              `<div class="agreement-section__box">
                <h4 key=${index}>${f.short_content}</h4>
                <div key=${index} class="agreement-section">${f.content}</div>
              </div>
              `
            )
          );
          setMessages({
            headerTitle: t("cms.plugins.register.agreement"),
            contentLabel: t("cms.plugins.register.agreement"),
            bodyContent: content,
            btnDeleteNo: t("cms.plugins.register.close"),
            type: regulation.type
          });
          openModal();
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      axiosSession
        .get("/api/rules/terms/active/")
        .then(response => {
          let items = response.data.results;
          content = items.map((f, index) =>
            parse(`<div key=${index} class="term-section">${f.content}</div>`)
          );
          setMessages({
            headerTitle: t("cms.plugins.register.termSingular"),
            contentLabel: t("cms.plugins.register.termSingular"),
            bodyContent: content,
            btnDeleteNo: t("cms.plugins.register.close")
          });
          openModal();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const setPasswordVisibility = pass => {
    event.preventDefault();
    if (pass === "password1") {
      return setIsPasswordHidden(!isPasswordHidden);
    }

    if (pass === "password2") {
      return setIsRepeatPasswordHidden(!isRepeatPasswordHidden);
    }
  };

  const onAgree = () => {
    setModalIsOpen(false);
    if (messages.type === "agreements") {
      setValue("agreements", "true");
    } else {
      setValue("terms", "true");
    }
  };

  return (
    <>
      <Portal container={document.querySelector(".skiplinks")}>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".header__nav-open")}
        >
          {t("cms.plugins.register.skiplinks.menu")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__container")}
        >
          {t("cms.plugins.register.skiplinks.form")}
        </button>
        <button
          className="skiplink btn"
          onClick={() => handleSkiplinkClick(".login__title-row__register")}
        >
          {t("cms.plugins.register.skiplinks.login")}
        </button>
      </Portal>
      <section className="section wrapper">
        <Modal
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          title={messages?.headerTitle ?? ""}
          content={messages?.bodyContent ?? ""}
          buttons={
            <button
              className="btn"
              onClick={onAgree}
              aria-label={t("cms.plugins.register.agree")}
            >
              {t("cms.plugins.register.agree")}
              &nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          }
        />
        <nav className="breadcrumbs">
          <ul>
            <li>
              <a
                className="breadcrumbs__frontoffice-link"
                href="/"
                title={t("cms.plugins.login.mainPage")}
              >
                {t("cms.plugins.login.mainPage")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <a className="breadcrumbs__frontoffice-link" href={login_url}>
                {t("cms.plugins.login.login")}
              </a>
            </li>
            <li>
              <span>&rarr;</span>
            </li>
            <li>
              <span className="breadcrumbs__current">
                {t("cms.plugins.register.register")}
              </span>
            </li>
          </ul>
        </nav>

        <section className="section">
          <h1 id="mbc_header">{t("cms.plugins.register.register")}</h1>
          <div className="login__title-row">
            <h2 className="header--decorated">
              {t("cms.plugins.register.register")}
            </h2>
            <ArrowButton href="/login" className="login__title-row__register">
              {t("cms.plugins.register.haveAccount")}
            </ArrowButton>
          </div>
          <div className="login__container">
            <div className="form-group">
              {/* <a
                className="btn btn--facebook"
                href={"/accounts/facebook/login"}
              >
                <FontAwesomeIcon
                  className="icon-facebook"
                  icon={faFacebookSquare}
                  aria-hidden="true"
                />
                {t("cms.loginExternal.facebook")}
              </a> */}
              <a className="btn btn--google" href={"/accounts/google/login"}>
                <img
                  className="google-icon"
                  src={googleIcon}
                  aria-hidden="true"
                />
                {t("cms.loginExternal.google")}
              </a>
            </div>
            <p className="login__or-row">{t("cms.plugins.register.or")}</p>
            <form
              className="form-group"
              onSubmit={handleSubmit(onSubmit)}
              autocomplete="on"
            >
              <Input
                placeholder={t("cms.plugins.placeholders.name")}
                ariaLabel={`${t("cms.plugins.placeholders.name")} ${t(
                  "app.requiredField"
                )}`}
                name={"first_name"}
                label={t("cms.plugins.register.name")}
                darkTheme={true}
                register={register({
                  required: t("cms.plugins.register.errors.required")
                })}
                errors={errors}
                required
              />

              <Input
                placeholder={t("cms.plugins.placeholders.surname")}
                ariaLabel={`${t("cms.plugins.placeholders.surname")} ${t(
                  "app.requiredField"
                )}`}
                name={"last_name"}
                label={t("cms.plugins.register.lastName")}
                required
                darkTheme={true}
                register={register({
                  required: t("cms.plugins.register.errors.required")
                })}
                errors={errors}
              />

              <Input
                placeholder={t("cms.plugins.placeholders.email")}
                ariaLabel={`${t("cms.plugins.placeholders.email")} ${t(
                  "app.requiredField"
                )}`}
                name={"email"}
                label={"Email"}
                required
                darkTheme={true}
                register={register({
                  required: t("cms.plugins.register.errors.required")
                })}
                errors={errors}
              />

              <Input
                placeholder={t("cms.plugins.placeholders.usernameRegister")}
                ariaLabel={`${t(
                  "cms.plugins.placeholders.usernameRegister"
                )} ${t("app.requiredField")}`}
                name={"username"}
                required
                label={t("cms.plugins.register.username")}
                darkTheme={true}
                register={register({
                  required: t("cms.plugins.register.errors.required")
                })}
                errors={errors}
                autocomplete="off"
              />
              <div className="input-container">
                <Input
                  placeholder={t("cms.plugins.placeholders.passwordRegister")}
                  ariaLabel={`${t(
                    "cms.plugins.placeholders.passwordRegister"
                  )} ${t("app.requiredField")}`}
                  className="login__password-field"
                  type={isPasswordHidden ? types.password : types.text}
                  name={"password1"}
                  label={t("cms.plugins.register.password")}
                  required
                  register={register({
                    required: t("cms.plugins.register.errors.required")
                  })}
                  errors={errors}
                  autocomplete="off"
                />
                <button
                  className="icon-end"
                  onClick={() => setPasswordVisibility("password1")}
                  aria-label={t("cms.plugins.setPassword.passwordPreview")}
                >
                  <FontAwesomeIcon
                    className="show-password-btn__icon"
                    icon={isPasswordHidden ? faEye : faEyeSlash}
                  />
                </button>
              </div>
              <p>
                {t("cms.plugins.register.passwordTip1")}
                <strong>{t("cms.plugins.register.passwordTipBold")}</strong>
                {t("cms.plugins.register.passwordTip2")}
              </p>
              <div className="input-container">
                <Input
                  placeholder={t("cms.plugins.placeholders.repeatPassword")}
                  ariaLabel={`${t(
                    "cms.plugins.placeholders.repeatPassword"
                  )} ${t("app.requiredField")}`}
                  className="login__password-field"
                  type={isRepeatPasswordHidden ? types.password : types.text}
                  name={"password2"}
                  label={t("cms.plugins.register.rePassword")}
                  required
                  register={register({
                    required: t("cms.plugins.register.errors.required"),
                    validate: value =>
                      value === getValues()["password1"] ||
                      t("cms.plugins.register.errors.passwordMismatch")
                  })}
                  errors={errors}
                  autocomplete="off"
                />

                <button
                  className="icon-end"
                  onClick={() => setPasswordVisibility("password2")}
                  aria-label={t("cms.plugins.setPassword.passwordPreview")}
                >
                  <FontAwesomeIcon
                    className="show-password-btn__icon"
                    icon={isRepeatPasswordHidden ? faEye : faEyeSlash}
                  />
                </button>
              </div>
              <div className="login__links">
                {regulations.map(item => (
                  <React.Fragment key={item.id}>
                    <CMSCheckbox
                      name={item.name}
                      value={!!item.id}
                      register={register({
                        required: t("cms.plugins.register.errors.required")
                      })}
                      errors={errors}
                    >
                      <span
                        aria-label={`${item.label} ${item.link} ${t(
                          "app.requiredField"
                        )}`}
                      >
                        {item.label}
                        <button
                          type="button"
                          className="login__links__button"
                          onClick={() => displayDialog(item)}
                          aria-label={`${t("cms.plugins.register.open")} ${
                            item.link
                          }`}
                        >
                          {item.link} *
                        </button>
                      </span>
                    </CMSCheckbox>
                  </React.Fragment>
                ))}
                <div>
                  <ReCAPTCHA
                    className="captcha"
                    ref={reCaptchaRef}
                    onChange={onVerifyCaptcha}
                    sitekey={reCaptchaSiteKey}
                  />
                  <p className="is-error-text">
                    {errors.recaptcha &&
                      t("cms.plugins.register.errors.required")}
                    {inputErrorCaptcha}
                  </p>
                </div>
              </div>
              <button className="btn">
                {t("cms.plugins.register.registerUp")}
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
              <ArrowButton href={login_url} className="login__links__register">
                {t("cms.plugins.register.haveAccount")}
              </ArrowButton>
              <span className="login__rodo">
                <h3 className="has-text-white">
                  {t("cms.plugins.register.rodoTitle")}
                </h3>
                <p>{t("cms.plugins.register.rodoContent")}</p>
              </span>
            </form>
            <div>
              <p>
                <span className="color-red">*</span> -{" "}
                {t("app.requiredDescription")}
              </p>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Register;
